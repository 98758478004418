<template>
  <ValidationProvider
    :rules="validationRules"
    :class="width"
    :ref="`${question.qid}-validation-provider`"
    :vid="`${question.qid}-vid`"
    :mode="'passive'"
    class="question column"
    tag="div"
    v-slot="{ errors, dirty, valid }"
  >
    <div class="question-text">
      <p class="question-text__code" v-if="showQuestionCode">
        {{ question.code }}
      </p>
      <p class="question-text__question" v-html="decodeHtml(question.question[lang])"></p>
      <p class="help is-danger" v-if="errors[0]">{{ errors[0] }}</p>
      <p class="help" v-else>{{ questionHelp }}</p>
    </div>
    <div class="question-content card">
      <div class="card-content">
        <b-field
          :type="{ 'is-danger': errors[0], 'is-success': dirty && valid }"
        >
          <b-input
            :disabled="loading"
            :value="value[question.qid]"
            @input="$emit('input', { [question.qid]: $event })"
            custom-class="fa-input"
            type="textarea"
            rows="1"
            :placeholder="$t('free_answer.placeholder')"
            v-autogrow
          ></b-input>
        </b-field>
      </div>
    </div>
  </ValidationProvider>
</template>
<script>
import { QuestionMixin } from "@/mixins/question";
import { TextareaAutogrowDirective } from "@/directives/textarea-auto-grow.directive";

export default {
  name: "FreeAnswer",
  mixins: [QuestionMixin],
  directives: {
    autogrow: TextareaAutogrowDirective,
  },
  data(){
    return {
      width: 'is-8'
    }
  },
  async mounted() {
    setTimeout(() => {
      const numberOfQuestions = document.getElementsByClassName('question').length;
      if(numberOfQuestions > 1){
        const previousQuestionClass = Array.from(document.getElementsByClassName('question')[0].classList).find(el => el.startsWith('is'))
        this.width = previousQuestionClass ?? this.width
      }
      this.$emit("mounted", this.width);
    }, 250);    
  },
};
</script>

<style lang="scss">
.textarea[rows] {
  min-height: 32px;
}
textarea.fa-input {
  background-color: transparent;
  box-shadow: none;
  color: #4a4a4a;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  border: none;
  border-bottom: 1px solid $light-green;
  border-radius: 0;
  padding: 0;
  outline: 0;
  &:focus {
    box-shadow: none !important;
  }
}
</style>
